<template>
    <div class="my-popup">
        <div class="container" v-show="isShowBox && onceLoading" :style="containerZIndex">
            <div class="mask"></div>
            <div class="content" :class="[isShow ? 'popup-in' : 'popup-out']" @animationend="toggleShowBox(isShow)">
                <div class="head">
                    <div class="left iconfont" @click="leftHandler">{{ leftIcon }}</div>
                    <div class="center-title">{{ title }}</div>
                    <div class="right iconfont" @click="rightHandler">{{ right }}</div>
                </div>
                <div class="body">
                    <el-scrollbar style="height: 100%">
                        <slot></slot>
                    </el-scrollbar>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MyPopup",
    data() {
        return {
            onceLoading: false,
            isShowBox: true,
        };
    },
    props: {
        isShow: {
            type: Boolean,
            default: false,
        },
        leftIcon: {
            type: String,
            default: "\ue6e9",
        },
        right: {
            type: String,
            default: "",
        },
        title: {
            type: String,
            default: "",
        },
        zIndex: {
            type: [String, Number],
            default: "auto",
        },
    },
    watch: {
        isShow(newState) {
            if (newState) {
                this.isShowBox = newState;
                this.onceLoading || this.$set(this, "onceLoading", true);
            }
        },
    },
    computed: {
        containerZIndex() {
            return {
                zIndex: this.zIndex,
            };
        },
    },
    components: {},
    methods: {
        leftHandler() {
            this.$emit("leftHandler");
        },
        rightHandler() {
            this.$emit("rightHandler");
        },
        toggleShowBox(state) {
            if (!state) {
                this.isShowBox = false;
            }
            this.$emit("animateEnd");
        },
    },
    mounted() {},
};
</script>

<style scoped>
@keyframes popupIn {
    0% {
        transform: translate(0, 100%);
    }
    100% {
        transform: translate(0, 0);
    }
}

@keyframes popupOut {
    0% {
        transform: translate(0 0);
    }
    100% {
        transform: translate(0, 100%);
    }
}

.popup-in {
    animation: popupIn 0.3s ease 0s 1 alternate both running;
}

.popup-out {
    animation: popupOut 0.3s ease 0s 1 alternate both running;
}
</style>

<style scoped lang="scss">
.my-popup {
    .container {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        .mask {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.3);
        }
        .content {
            box-sizing: border-box;
            flex-direction: column;
            display: flex;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 80%;
            background-color: #fff;
            z-index: 99;
            border-radius: 20px 20px 0 0;
            .head {
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                align-items: center;
                box-sizing: border-box;
                padding: 17px;
                color: #060111;
                border-bottom: 1px solid #ddd;
                .left,
                .right {
                    width: 50px;
                    cursor: pointer;
                }
                .left {
                    font-size: 24px;
                }
                .right {
                    text-align: end;
                    color: #4b45ff;
                    font-size: 16px;
                }
                .center-title {
                    text-align: center;
                    font-weight: 700;
                    font-size: 18px;
                }
            }
            .body {
                box-sizing: border-box;
                flex: 1;
                overflow: hidden;
            }
        }
    }
}
::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
}
</style>
