<template>
    <div class="stu-live" v-loading="connectLoading" element-loading-text="连接中">
        <div class="left-info">
            <div class="name-avatar">
                <!-- <span class="stu-avatar"> -->
                    <img class="stu-avatar" :src="liveRoomInfo.avatar || require('../../assets/image/blank_headPic.png')">
                <!-- </span> -->
                <div class="nickname-fans">
                    <span class="stu-nickname">{{ liveRoomInfo.nickname }}</span>
                    <span class="fans-number">{{ latestData.fans }}粉丝</span>
                </div>
                <div class="follow" v-if="followFlag == false" @click="followOrCancel">
                    <span class="follow-word" >关注</span>
                </div>
                <div class="follow"  @click="followOrCancel" v-if="followFlag == true" style="background: linear-gradient(85deg, #FFA200, #F78B43);" >
                    <span class="follow-icon"></span>
                </div>
            </div>
            <div class="stu-label">
                <div class="stu-label-item" v-for="(item, key) in stuLabel" :key="key">
                    <span class="label-icon"></span>
                    <span class="label-word">{{ item }}</span>
                </div>
            </div>
            <div class="stu-synopsis">{{ liveRoomInfo.description }}</div>
            <div class="stu-likes-number">
                <div class="likes-icon-item" v-if="!likesFlag" @click="giveOrCancelLikes"></div>
                <div class="likes-icon" v-else @click="giveOrCancelLikes"></div>
                <div class="likes-number">
                    <div class="likes-numb-word">点赞数</div>
                    <div><span class="likes-n">{{ likesNumber }}</span><span class="likes-w">w</span></div>
                </div>
            </div>
        </div>

        <div class="mid-screen">
            <div class="live-video-container-box" ref="parentLiveVideo">
            </div>
            <!-- <div class="live-video" id="live-video"></div> -->
            <div class="top-box">
                <div class="left-bag-coupon">
                    <!-- <div class="item bag-box" @click="receiveBag" v-if="luckyBagHandle.status == 1 && luckyBagHandle.countdown > 0 && liveRoomInfo.status != 2"> -->
                    <div class="item bag-box" @click="receiveBag" v-if="!!luckyBagHandle && luckyBagHandle.countdown > 0 && playerStatus !== '2'">
                        <div class="item-container">
                            <img class="icon" src="~assets/image/othersLive/luck-bag.png" alt="福袋" title="福袋" />
                        </div>
                        <div class="countdown">{{ luckyBagHandle.has_countdown | bagCountdown }}</div>
                    </div>
                    <!-- <div class="item coupon-box" v-if="couponHandle.status == 1 || couponHandle.status == 2">
                        <div class="item-container" @click="grabCoupons(0)">
                            <img class="icon" src="~assets/image/othersLive/coupon.png" alt="优惠券" title="优惠券" />
                        </div>
                        <div class="corner-mark">{{ couponHandle.coupon_num }}</div>
                    </div> -->
                    <div class="item coupon-box" v-if="couponHandle.length > 0 && playerStatus !== '2'">
                        <!-- <div class="item-container" @click="grabCoupons(0)" v-if="couponHandle.length > 0"> -->
                        <div class="item-container" @click="openCoupon">
                            <img class="icon" src="~assets/image/othersLive/coupon.png" alt="优惠券" title="优惠券" />
                        </div>
                        <div class="corner-mark">{{ couponHandle.length }}</div>
                    </div>
                </div>
                <div class="right-goods-info" v-if="chooseGoodsHandle.goods_id">
                    <!-- <div class="left-paster">
                        <img
                            class="paster-img"
                            :src="chooseGoodsHandle.goods_info.goods_master_img"
                        />
                    </div>
                    <div class="right-info">
                        <div class="title" v-html="chooseGoodsHandle.goods_info.goods_name"></div>
                        <div class="price-box">
                            <div class="old-price">
                                <span class="label">日常价: </span>
                                <span class="price">￥{{ chooseGoodsHandle.goods_info.goods_market_price }}</span>
                            </div>
                            <div class="new-price">
                                <span class="label">宠粉价: </span>
                                <span class="price">￥{{ chooseGoodsHandle.goods_info.goods_discount_price }}</span>
                            </div>
                        </div>
                    </div> -->
                    <img :src="chooseGoodsHandle.goods_live_patch"/>
                </div>
            </div>
            <div class="bottom-box" v-show="!openCouponVal && playerStatus !== '2'">
                <div class="lift-bullet-action">
                    <div class="bullet-chat" v-if="!bulletChatFlag">
                        <el-scrollbar style="height: 100%">
                            <div class="bullet-chat-list" >
                                <div class="item" v-for="(item, index) in barrageHandle" :key="index"  ref="bulletScreen">
                                    <span class="username" v-if="item.nickname">{{ item.nickname }}：</span>
                                    <span class="content">{{ item.barrage }}</span>
                                </div>
                            </div>
                        </el-scrollbar>
                    </div>
                    <div class="bullet-chat" v-else></div>
                    <div class="action-input">
                        <el-input class="input" v-model="actionValue" placeholder="说点什么..." @keydown.enter.native="keyDown" ref="input"></el-input>
                        <span class="action-icon"></span>
                    </div>
                </div>
                <div class="right-goods-popup" v-if="chooseGoodsHandle.goods_id">
                    <div class="img-box">
                        <div class="explain">讲解中</div>
                        <img
                            class="goods-img"
                            :src="chooseGoodsHandle.goods_info.goods_master_img"
                        />
                    </div>
                    <div class="goods-info">
                        <div class="title">{{ chooseGoodsHandle.goods_info.goods_name }}</div>
                        <template v-if="!!chooseGoodsHandle.currentGoodCoupons">
                            <div class="coupon" @click="getCoupon(chooseGoodsHandle.currentGoodCoupons)" v-if="chooseGoodsHandle.currentGoodCoupons.coupon_type === 1">
                                <span class="coupon-item">满</span>
                                <span class="coupon-item-numb">{{ parseInt(chooseGoodsHandle.currentGoodCoupons.coupon_full) }}</span>
                                <span class="coupon-item">减</span>
                                <span class="coupon-item-numb">{{ parseInt(chooseGoodsHandle.currentGoodCoupons.coupon_minus) }}</span>
                            </div>
                            <div class="coupon" @click="getCoupon(chooseGoodsHandle.currentGoodCoupons)" v-if="chooseGoodsHandle.currentGoodCoupons.coupon_type === 2">
                                <span class="coupon-item">满</span>
                                <span class="coupon-item-numb">{{ parseInt(chooseGoodsHandle.currentGoodCoupons.coupon_full) }}</span>
                                <span class="coupon-item">打</span>
                                <span class="coupon-item-numb">{{ parseInt(chooseGoodsHandle.currentGoodCoupons.coupon_minus) }}</span>
                                <span class="coupon-item">折</span>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="end-live" v-if="playerStatus == '0'">暂未开播</div>
            <div class="end-live" v-else-if="playerStatus == '1'">直播已暂停</div>
            <div class="end-live" v-else-if="playerStatus == '2'">该场直播已结束</div>
            <el-dialog width="400px" top="15%" :visible.sync="luckyBagDialog" class="luckyBagDialog" :modal-append-to-body="false">
                <div class="avatar">
                    <img class="stu-avatar" :src="liveRoomInfo.avatar || require('../../assets/image/blank_headPic.png')">
                </div>
                <div class="condition">关注我才可以领福袋哦</div>
                <div class="icon"></div>
                <div class="bottom-box">
                    <div class="left-btn" @click="cancelBagDialog">我再想想</div>
                    <div class="right-btn" @click="followOrCancel">立即关注</div>
                </div>
            </el-dialog>

            <el-dialog width="400px" top="15%" :visible.sync="followBagDialog" class="luckyBagDialog" :modal-append-to-body="false" v-if="!!luckyBagHandle">
                <div class="title">{{ luckyBagHandle.bag_name }}</div>
                <div class="mid-box">
                    <div class="count-down">
                        <div class="times">
                            <div class="times-numb">{{ luckyBagHandle.has_countdown | bagCountdown }}</div>
                            <div class="times-word">倒计时</div>
                        </div>
                        <div class="people-numb">可中奖数{{ luckyBagHandle.winning_number }}人 | {{ luckyBagHandle.number }}人已参与</div>
                    </div>
                    <div class="participate">
                        <div class="part-condition">参与条件</div>
                        <div class="part-word">参与口令：{{ luckyBagHandle.command_title }}</div>
                    </div>
                </div>
                <div class="send" @click="sendWord">去发送口令</div>
            </el-dialog>
            <!-- 领取优惠券 -->
            <my-popup v-show="openCouponVal" :is-show="openCouponVal" title="优惠券" @leftHandler="closeCouponPopup" z-index="99" class="coupon-popup">
                <div class="content">
                    <template v-for="item in couponHandle">
                        <div class="coupon-item" :key="item.id">
                            <div class="left-coupon-size">
                                <div class="discount" v-if="item.coupon_type === 2">
                                    <div class="num">
                                        <span>{{ parseInt(item.coupon_minus) }}</span>
                                        <span class="decorate">折</span>
                                    </div>
                                    <div class="amount">满{{ parseInt(item.coupon_full) }}元可用</div>
                                </div>
                                <div class="full-reduction" v-else>
                                    <div class="num">
                                        <span class="decorate">￥</span>
                                        <span>{{ parseInt(item.coupon_minus) }}</span>
                                    </div>
                                    <div class="amount">满{{ parseInt(item.coupon_full) }}元可用</div>
                                </div>
                            </div>
                            <div class="center-info">
                                <div class="title-box">
                                    <div class="decorate">商品券</div>
                                    <div class="title">{{ item.coupon_name }}</div>
                                </div>
                                <div class="desc-info">
                                    <div class="info-item">仅限指定商品组合使用</div>
                                    <div class="info-item">每人限领{{ item.everyone_count }}张</div>
                                </div>
                            </div>
                            <div class="right-action added" v-if="item.has_receive_num === item.everyone_count">
                                <div class="wavy-line">
                                    <div class="circle-item" v-for="(dotItem, index) in 8" :key="index"></div>
                                </div>
                                <div class="row-item one-row">已领完</div>
                            </div>
                            <div class="right-action" @click="getCoupon(item)" v-else>
                                <div class="mask"></div>
                                <div class="wavy-line">
                                    <div class="circle-item" v-for="(dotItem, index) in 8" :key="index"></div>
                                </div>
                                <div class="row-item one-row">立即</div>
                                <div class="row-item two-row">领券</div>
                            </div>
                        </div>
                    </template>
                </div>
            </my-popup>
            <el-dialog width="360px" top="11%" :visible.sync="couponDialog" class="couponDialog" :modal-append-to-body="false">
                <div class="avatar">
                    <img class="stu-avatar" :src="liveRoomInfo.avatar || require('../../assets/image/blank_headPic.png')">
                </div>
                <div class="coupon-tips">恭喜你，领券成功</div>
                <div class="coupon-details">
                    <div class="discount" v-if="currentCoupon.coupon_type == 1">
                        <span class="discount-word">满</span>
                        <span class="discount-numb">{{ parseInt(currentCoupon.coupon_full) }}</span>
                        <span class="discount-word">减</span>
                        <span class="discount-numb">{{ parseInt(currentCoupon.coupon_minus) }}</span>
                    </div>
                    <div class="discount" v-if="currentCoupon.coupon_type == 2">
                        <span class="discount-numb">{{ parseInt(currentCoupon.coupon_minus) }}</span>
                        <span class="discount-word">折</span>
                    </div>
                    <div class="category-name">
                        <div class="name"><span class="name-item">商品券</span></div>
                        <div class="category">{{ currentCoupon.coupon_name }}</div>
                    </div>
                    <div class="use-limited">
                        <div class="use-details">仅限指定商品组合使用</div>
                        <div class="limited">每人限领{{ currentCoupon.everyone_count }}张</div>
                    </div>
                    <div class="use-now"><span @click="useCoupon">立即用券</span></div>
                </div>
            </el-dialog>
        </div>

        <div class="right-operation">
            <div class="top-box">
                <el-scrollbar style="height: 100%">
                    <div class="title-box">
                        <img class="decorate" src="~assets/image/student/live/scriptRightDecorate.png" />
                        <div class="title">在线人数TOP10</div>
                    </div>
                    <div class="list">
                        <div class="container">
                            <div class="rank-item" v-for="(item, index) in audienceSortList" :key="index">
                                <div class="rank rank-one" v-if="index +1 == 1"></div>
                                <div class="rank rank-two" v-else-if="index +1 == 2"></div>
                                <div class="rank rank-three" v-else-if="index +1 == 3"></div>
                                <div class="rank rank-word" v-else>{{ index +1 }}</div>
                                <div class="item">
                                    <div class="avatar-box">
                                        <img class="avatar-img" :src="item.avatar" alt="user" title="user" />
                                    </div>
                                    <div class="desc">
                                        <span class="username" v-if="item.nickname">{{ item.nickname }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-scrollbar>
            </div>
            <div class="mid-box">
                <el-scrollbar style="height: 100%">
                    <div class="title-box">
                        <img class="decorate" src="~assets/image/student/live/scriptRightDecorate.png" />
                        <div class="title">历史弹幕</div>
                    </div>
                    <div class="list">
                        <div class="container">
                            <div class="item" v-for="(item, key) in historicalBarrage" :key="key">
                                <div class="avatar-box">
                                    <img class="avatar-img" :src="item.avatar" alt="user" title="user" />
                                </div>
                                <div class="desc">
                                    <span class="username">{{ item.nickname }}：</span>
                                    <span>{{ item.barrage }}</span>
                                    <!-- <span v-else>进入直播间进入直播间进入直播间进入直播间进入直播间进入直播间进入直播间进入直播间</span> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </el-scrollbar>
            </div>
            <div class="bottom-box">
                <div class="barrage-switch" @click="switchBarrage">
                    <div class="open" v-if="bulletChatFlag == false"></div>
                    <div class="shut" v-if="bulletChatFlag == true"></div>
                </div>
                <div class="volume-adjusting">
                    <div class="volume-icon"></div>
                    <c-progress class="c-progress"
                    :percent="percent" @percentChange="onPercentChange" :show-per-text="false" :width="150"
                    :sliderWidth="16" :progressColor="'#4B45FF'"/>
                </div>
                <div class="enlarge"></div>
            </div>
        </div>

    </div>
</template>

<script>
import { getBagCommand } from '@/utils/apis.js';
import CProgress from '../teacher/studentLive/Progress';
import MyPopup from "../../components/student/liveRoom/liveMain/containers/MyPopup.vue";

export default {
    data() {
        return {
            wsServer: 'wss://zblive.e-class.me/wss',
            // wsServer: 'wss://zhibo.keketong.xyz:2388',
            ws: null,
            player: null,
            playerURL: this.$route.query.play_url,
            play_url_rtmp: this.$route.query.play_url_rtmp,
            playerStatus: '',
            paused: false,
            stuLabel: [],
            actionValue: '',
            percent: 30,
            likesFlag: false,
            followFlag: false,
            bulletChatFlag: false,
            couponDialog: false,
            luckyBagDialog: false,
            followBagDialog: false,
            connectLoading: true,
            bagCountdown: 0,
            nowGoodsID: '',
            bagTimer: null,
            commonParams: {
                room_id: this.$route.query.t_Room_id,
                train_id: this.$route.query.t_Train_id,
                script_id: this.$route.query.script_id,
                user_id: this.$route.query.tid,
                bind_id: this.$route.query.bind_id,
                advert_id: this.$route.query.advert_id,
                nickname: localStorage.getItem('nickname'),
                avatar: localStorage.getItem('role') == 4 ? localStorage.getItem('teacherAvatar') : localStorage.getItem('studentAvatar'),
                send_msg_user_id: localStorage.getItem('role') == 4 ? localStorage.getItem('teacherId') : localStorage.getItem('studentId'),
            },
            liveRoomInfo: {},
            historicalBarrage: [],
            sendBarrage: [],
            audienceSortList: [],
            luckyBagData: {},
            couponData: [],
            chooseGoodsData: [],
            BagCommandData: [],
            latestData: {},
            luckyBag: {},
            couponNum: {},
            openCouponVal: false,
            currentCoupon: {},
            chartletArr: [],
            beatTimer: void 0,
            firstLoad: true,
            isPushing: 0,
        }
    },
    mounted() {
        this.init();
        this.getLuckyBagCommand();
        window.addEventListener('beforeunload', e => this.beforeunloadHandler(e));
    },
    components: {
        CProgress,
        MyPopup,
    },
    computed: {
        luckyBagHandle() {
            // let luckyBagObj = {}
            // if (this.luckyBagData != undefined) {
            //     for (let x = 0; x < this.luckyBagData.length; x ++) {
            //         if (this.luckyBagData[x].status == 1) {
            //             luckyBagObj = this.luckyBagData[x];
            //             if (this.luckyBagData[x].countdown <= 0) {
            //                 localStorage.removeItem('time');
            //                 // localStorage.setItem('time', 0);
            //                 clearTimeout(this.bagTimer);
            //             } else {
            //                 this.bagTimer = setTimeout(() => {
            //                     this.luckyBagData[x].countdown --;
            //                     localStorage.setItem('time', this.luckyBagData[x].countdown);
            //                 }, 1000);
            //             };
            //             if (localStorage.getItem('time') != '' || localStorage.getItem('time') != null || localStorage.getItem('time') != undefined) {
            //                 this.luckyBagData[x].countdown = Number(localStorage.getItem('time'));
            //             };
            //         };
            //     }
            // };
            // for (let i = 0; i < this.BagCommandData.length; i ++) {
            //     if (luckyBagObj.command_id == this.BagCommandData[i].id) {
            //         luckyBagObj.command_title = this.BagCommandData[i].title;
            //     };
            // };
            // return luckyBagObj;
            let luckyBag = {};
            if (this.luckyBagData.length > 0) {
                luckyBag = this.luckyBagData.find(o => o.status === 1 && o.has_countdown > 0);
                if(!!luckyBag) {
                    this.BagCommandData.forEach((e) => {
                        if (luckyBag.command_id === e.id) luckyBag.command_title = e.title;
                    });
                    this.bagTimer = setTimeout(() => {
                        luckyBag.has_countdown --;
                    }, 1000);
                    if (luckyBag.has_countdown < 0) {
                        clearTimeout(this.bagTimer);
                        if (followBagDialog) followBagDialog = false;
                    }
                }
            }
            return luckyBag;
        },
        couponHandle() {
            // let couponObj = {};
            // if (this.couponData.length > 0) {
            //     for (let x = 0; x < this.couponData.length; x ++) {
            //         for (let i = 0; i < this.couponData[x].child.length; i ++) {
            //             if (this.nowGoodsID === this.couponData[x].child[i].goods_id ) {
            //                 couponObj.coupon_name = this.couponData[x].coupon_name;
            //                 couponObj.coupon_type = this.couponData[x].coupon_type;
            //                 couponObj.coupon_num = this.couponData[x].child.length
            //                 couponObj.goods_id = this.couponData[x].child[i].goods_id;
            //                 couponObj.coupon_student_id = this.couponData[x].child[i].coupon_student_id
            //                 couponObj.status = this.couponData[x].child[i].status;
            //                 couponObj.coupon_full = this.couponData[x].child[i].coupon_full;
            //                 couponObj.coupon_minus = this.couponData[x].child[i].coupon_minus;
            //             };
            //         };
            //     };
            // };
            // return couponObj;
            let couponArr = [];
            this.couponData.forEach((e) => {
                e.child.map(o => {
                    o.coupon_name = e.coupon_name;   // 优惠券名称
                    o.coupon_type = e.coupon_type;   // 优惠券类型
                    o.everyone_count = e.everyone_count;   // 优惠券限领数
                })
                e.sent_coupon = e.child.filter(o => o.status !== 0);   // 已发送的优惠券
                if(e.sent_coupon.length > 0){
                    couponArr = [...couponArr, ...e.sent_coupon];
                }
            });
            // console.log({'后台数据': this.couponData, '优惠券': couponArr});
            return couponArr;
        },
        chooseGoodsHandle() {
            let nowGoodsInfo = {}
            if (this.chooseGoodsData.length > 0) {
                for (let s = 0; s < this.chooseGoodsData.length; s ++) {
                    if (this.nowGoodsID == this.chooseGoodsData[s].goods_id) {
                        let arrEntities = {
                            'lt': '<',
                            'gt': '>',
                            'nbsp': ' ',
                            'amp': '&',
                            'quot': '"'
                        };
                        this.chooseGoodsData[s].goods_info.goods_body = this.chooseGoodsData[s].goods_info.goods_body.replace(/&(lt|gt|nbsp|amp|quot);/ig,
                            function (all, t) { return arrEntities[t]; });
                        nowGoodsInfo = this.chooseGoodsData[s];
                    }
                    this.chartletArr.forEach((e) => {
                        if (e.goods_id === this.chooseGoodsData[s].goods_id) {
                            this.chooseGoodsData[s].goods_live_patch = e.goods_live_patch;
                        }
                    })
                    this.chartletArr.forEach((e) => {
                        if (e.goods_id === this.chooseGoodsData[s].goods_id) {
                            this.chooseGoodsData[s].goods_live_patch = e.goods_live_patch;
                        }
                    })
                    this.couponHandle.forEach((e) => {
                        if (e.goods_id === this.chooseGoodsData[s].goods_id) {
                            if (!this.chooseGoodsData[s].currentGoodCoupons) this.chooseGoodsData[s].currentGoodCoupons = e;
                        }
                    })
                }
            }
            // console.log ({nowGoodsInfo});
            return nowGoodsInfo
        },
        likesNumber() {
            let likes = 0;
            if (!!this.latestData.like) {
                likes = this.latestData.like / 10000;
                return likes.toFixed(3);
            } else {
                return likes
            };
        },
        barrageHandle() {
            if (this.sendBarrage.length > 0) {
                return this.historicalBarrage = [...this.historicalBarrage,...[this.sendBarrage[this.sendBarrage.length-1]]];
            } else {
                return this.historicalBarrage;
            };
        },
        wsActions() {
            const {room_id, train_id, script_id, user_id, nickname, avatar, send_msg_user_id, bind_id} = this.commonParams;
            const obj = {
                bind: { type: 'bind', room_id: room_id, user_id: bind_id},
                historicalBarrage: { type: 'get_all_barrage', train_id: train_id, user_id: user_id},
                sendBarrag: {
                    type: 'send_barrage',
                    room_id: room_id,
                    msg: {nickname: nickname, avatar: avatar, send_msg_user_id: send_msg_user_id, barrage: '', timestamp: 0}
                },
                audienceSort: { type: 'get_audience_sort', train_id: train_id, room_id: room_id},
                stuOperation: { type: 'get_student_data', user_id: user_id, train_id: train_id, script_id: script_id, room_id: room_id, receive_user_id: send_msg_user_id},
                interpretation: { type: 'get_interpretation_goods', room_id: room_id, goods_id: ''},
                stuNowShelvesGoods: { type: 'get_student_now_shelves_goods', user_id: user_id, train_id: train_id, script_id: script_id},
                newestData: {type: 'data', room_id: room_id},
            };
            return obj
        }
    },
    filters: {
        bagCountdown(time) {
            const value = time * 1;
            const times = [];
            const getValue = (value) => ((value + "").length <= 1 ? `0${value}` : value);
            let minute = getValue(Math.floor(value / 60) % 60);
            let second = getValue(value % 60);
            return [minute, second].join(":");
        },
    },
    methods: {
        init() {
            this.ws = new WebSocket(this.wsServer);
            this.ws.onopen = this.webSocketOpen;
            this.ws.onmessage = this.webSocketOnmessage;
            this.ws.onclose = this.webSocketClose;
            this.ws.onerror = this.webSocketError;
        },
        webSocketOpen() {
            this.connectLoading = false;
            // console.log('连接成功', this.ws.readyState);
            //绑定直播间
            this.ws.send(JSON.stringify(this.wsActions.bind));
            //历史弹幕
            this.ws.send(JSON.stringify(this.wsActions.historicalBarrage));
            //在线人数top
            this.ws.send(JSON.stringify(this.wsActions.audienceSort));
            //正在讲解的商品
            this.ws.send(JSON.stringify(this.wsActions.interpretation));
            //当前上架商品
            this.ws.send(JSON.stringify(this.wsActions.stuNowShelvesGoods));
            //优惠券、福袋、商品
            this.ws.send(JSON.stringify(this.wsActions.stuOperation));
            //发送弹幕
            this.keyDown();
            //直播间最新数据
            this.ws.send(JSON.stringify(this.wsActions.newestData));
            if (this.firstLoad) {
                this.firstLoad = false;
                this.setBeatCheck();
            }
        },
        webSocketOnmessage(evt) {
            let roomInfo = JSON.parse(evt.data);
            if (roomInfo.code == 200) {
                //绑定直播间数据
                if (roomInfo.data.web_type == 'bind') {
                    if(roomInfo.data['0'].status == 1) {
                        this.playerStatus = "";
                        this.initPlayer();
                    } else if (roomInfo.data['0'].status == 3) {
                        this.playerStatus = '1';
                        this.clearLive();
                    } else if (roomInfo.data['0'].status == 2) {
                        this.playerStatus = '2';
                        this.clearLive();
                        if(!!this.beatTimer) clearTimeout(this.beatTimer);
                    } else if (roomInfo.data['0'].status == 0) {
                        this.playerStatus = '0';
                    };
                    this.liveRoomInfo = roomInfo.data['0'];
                    this.stuLabel = roomInfo.data['0'].tag;
                    this.chartletArr = roomInfo.data['0'].goods_resourse;   // 贴片
                };
                //历史弹幕数据
                if (roomInfo.data.web_type == 'get_all_barrage') {
                    this.historicalBarrage = roomInfo.data.list;
                };
                //在线人数top数据
                if (roomInfo.data.web_type == 'get_audience_sort') {
                    this.audienceSortList = roomInfo.data.list;
                };
                //直播间最新数据
                if (roomInfo.data.web_type == 'data') {
                    this.latestData = roomInfo.data;
                };
                //当前上架商品
                // if (roomInfo.data.web_type == 'get_student_now_shelves_goods') {
                //     if (roomInfo.data.goods_id) {
                //         this.nowGoodsID = roomInfo.data.goods_id;
                //     };
                // };
                if (roomInfo.data.web_type == 'get_interpretation_goods') {
                    this.nowGoodsID = roomInfo.data.goods_id;
                };
                //优惠券、福袋、商品
                if (roomInfo.data.web_type == 'get_student_data') {
                    // console.log(roomInfo.data, 'get_student_data');
                    this.luckyBagData = roomInfo.data.student_bag_list;
                    this.chooseGoodsData = roomInfo.data.choose_goods_data;
                    this.couponData = roomInfo.data.student_coupon_list;
                };
                //发送弹幕数据
                if (roomInfo.data.web_type == 'send_barrage') {
                    this.sendBarrage.push(roomInfo.data);
                    this.$nextTick(() => {
                        if (this.$refs.bulletScreen[this.$refs.bulletScreen.length-1]) this.$refs.bulletScreen[this.$refs.bulletScreen.length-1].scrollIntoView(false);
                    });
                };
                //福袋优惠券领取
                if (roomInfo.data.web_type == 'receive_op') {
                    this.$message.success(roomInfo.msg);
                };
                //直播状态改变
                if (roomInfo.data.web_type == 'update_room_status') {
                    console.log({'直播状态':roomInfo.data.status});
                    if(roomInfo.data.status == 1) {
                        this.playerStatus = "";
                        this.initPlayer();
                    } else if (roomInfo.data.status == 3) {
                        this.playerStatus = '1';
                        this.clearLive();
                    } else if (roomInfo.data.status == 2) {
                        this.playerStatus = '2';
                        if(!!this.beatTimer) clearTimeout(this.beatTimer);
                        this.clearLive();
                    } else if (roomInfo.data.status == 0) {
                        this.playerStatus = '0';
                    };
                };
                if (roomInfo.data.web_type == 'check_is_has_push') {
                    this.isPushing = roomInfo.data.result;
                    console.log({'是否拉流': this.isPushing});
                }
            } else {
                if (roomInfo.msg && roomInfo.data.web_type == 'receive_op') this.$message.error(roomInfo.msg);
            };
        },
        webSocketClose(e) {
            console.log("链接中断", e);
            if (e.code != 1000) {
                this.connectLoading = true;
                this.init();
            }
        },
        webSocketError(err) {
            console.log("链接错误", err);
        },
        //发送弹幕
        keyDown() {
            if (this.bulletChatFlag) return this.$message.error("请开启弹幕功能！");
            // if (this.playerStatus === "0" || this.playerStatus === "1" || this.playerStatus === "2") {
            //     this.actionValue = '';
            //     this.$message.error("只有直播状态下才可以发送弹幕！");
            //     return;
            // }
            if (this.actionValue !== '') {
                this.wsActions.sendBarrag.msg.barrage = this.actionValue;
                this.wsActions.sendBarrag.msg.timestamp = new Date().getTime();
                this.ws.send(JSON.stringify(this.wsActions.sendBarrag));
                if (!!this.luckyBagHandle) {
                    let receiveBag = {type: 'receive_op',
                        id: this.luckyBagHandle.id,
                        genre: 2,
                        receive_user_id: this.commonParams.send_msg_user_id,
                        train_id: this.commonParams.train_id,
                        command_string: this.actionValue
                    };
                    if (this.actionValue == this.luckyBagHandle.command_title) {
                        this.ws.send(JSON.stringify(receiveBag))
                    }
                }
                this.actionValue = '';
            }
        },
        //直播画面
        async initPlayer() {
            if (!!this.player) {
				this.player.dispose();
				this.player = null;
			};
            // let playerOptions = {
            //     webrtc: this.playerURL,
            //     // rtmp: this.play_url_rtmp,
            //     autoplay : true,     //视频自动播放
            //     // controls: "none",  //不显示控件
            //     x5_orientation: 1, //竖屏
            //     live: true, //直播类型
            //     listener: (msg) => {
            //         this.player.mute(false);   // 消音 true
            //         console.log(msg.src.playState, 'Player');
            //         if (this.liveRoomInfo.status == 2) {
            //             this.playerStatus = '2';
            //         } else if (this.liveRoomInfo.status == 3) {
            //             this.playerStatus = '1';
            //         };
            //         // 播放失败，重新连接
			// 		if (msg.type == "error") {
			// 			this.playTimer = setTimeout(() => {
			// 				this.player.load();
			// 			}, 3000);
			// 		} else if (msg.type == 'play') {
            //             this.player.mute(false);
            //             this.playerStatus = '';
            //             clearTimeout(this.playTimer);
            //         };
            //         console.log(msg.src.playState);
            //         if (msg.src.playState == 'PAUSED') {
            //             // this.playerStatus = '1';
            //         } else if (msg.src.playState == 'PLAYING') {
            //             // this.playerStatus = '';
            //         } else if (msg.src.playState == 'IDLE') {

            //         };
            //     },
            // };
            // this.player = new TcPlayer('live-video', playerOptions);

            const createVideoContainer = () => {
                return new Promise((res, rej) => {
                    const parentNode = this.$refs.parentLiveVideo;
                    parentNode && this.$set(parentNode, "innerHTML", "");
                    const video = document.createElement("video");
                    video.setAttribute("ref", "liveVideo");
                    video.id = "live-video";
                    video.className = "live-video";
                    video.preload = "auto";
                    video.muted = "muted";
                    video.setAttribute("playsinline", "");
                    video.setAttribute("webkit_playsinline", "");
                    parentNode.appendChild(video);
                    video.addEventListener("load", res());
                });
            };
            await createVideoContainer();
            if (this.liveVideo) {
                try {
                    console.log(this.liveVideo);
                    this.liveVideo.stop();
                } catch (e) {
                    console.log(e);
                }
            }
            const player = TCPlayer("live-video", {
                sources: [{src:this.playerURL}],
                autoplay: true,
                controls: false,
                defaultRes: "HD",
                webrtcConfig: {
                    connectRetryCount: 999999,
                }
            });
            player.on("error", (err) => console.log(err));
            this.$nextTick(() => {
                player.play();
            });
            this.player = player;
        },
        //点赞取消点赞
        giveOrCancelLikes() {
            this.likesFlag = !this.likesFlag;
        },
        //关注取消关注
        followOrCancel() {
            if (this.followFlag) {
                this.$confirm('确定不再关注此人？', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.followFlag = false;
                }).catch(() => { });
            } else {
                this.followFlag = true;
            }
            // if (this.followFlag == false) {
            //     this.followFlag = true;
            //     this.cancelBagDialog()
            // } else if (this.followFlag == true) {
            //     this.followFlag = false;
            // }
        },
        // 领取优惠券弹窗
        openCoupon() {
            this.openCouponVal = true;
        },
        closeCouponPopup() {
            this.openCouponVal = false;
        },
        getCoupon(data) {
            this.currentCoupon = data;
            let receiveCoupon = {type: 'receive_op',
                id: data.id,
                genre: 1,
                receive_user_id: this.commonParams.send_msg_user_id,
                train_id: this.commonParams.train_id,
            };
            // this.couponNum[data.id] = !!this.couponNum[data.id]? this.couponNum[data.id] : 0;
            if (data.has_receive_num === data.everyone_count) {
                this.$message.error('优惠券已领完！');
                this.couponDialog = false;
            } else {
                this.ws.send(JSON.stringify(receiveCoupon))
                // this.couponNum[data.id] ++;
                this.couponDialog = true;
            }
            this.ws.send(JSON.stringify(this.wsActions.stuOperation));
        },
        //领取优惠券
        // grabCoupons(key) {
        //     let receiveCoupon = {type: 'receive_op',
        //         id: this.couponHandle.coupon_student_id,
        //         genre: 1,
        //         receive_user_id: this.commonParams.send_msg_user_id,
        //         train_id: this.commonParams.train_id,
        //     };
        //     if (key == this.couponNum) {
        //         this.ws.send(JSON.stringify(receiveCoupon))
        //         this.couponDialog = true;
        //         this.couponNum = key +1;
        //     } else {
        //         this.$message.warning('每人限领一张！')
        //     }
        // },
        //立即用券
        useCoupon() {
            this.couponDialog = false;
        },
        //领取福袋Dialog
        receiveBag() {
            if (this.luckyBagHandle.in_type == 1) {
                this.followBagDialog = true
            } else if (this.luckyBagHandle.in_type == 2) {
                this.followBagDialog = true;
            } else if (this.followFlag == false) {
                this.luckyBagDialog = true
            } else if (this.followFlag == true) {
                this.followBagDialog = true;
            }
        },
        cancelBagDialog() {
            this.followBagDialog = false
        },
        //发送口令
        sendWord() {
           this.cancelBagDialog();
            this.$nextTick(() => {
                this.$refs.input.focus();
            });
        },
        //开关弹幕
        switchBarrage() {
            if (this.bulletChatFlag == true) {
                this.bulletChatFlag = false;
            } else if (this.bulletChatFlag == false) {
                this.bulletChatFlag = true;
            }
        },
        //福袋口令列表
        async getLuckyBagCommand() {
            let res = await getBagCommand();
            this.BagCommandData = res.data
        },
        //进度条拖拽
        onPercentChange(per) {
            // console.log(per);
            // this.player.volume(per);
        },
        beforeunloadHandler(event) {
            if (event.clientX > document.body.clientWidth && event.clientY < 0 || event.altKey) {
                // localStorage.removeItem('time');
            } else {
                // if (this.luckyBagHandle.countdown > 0) {
                //     localStorage.setItem('time', this.luckyBagHandle.countdown);
                // } else {
                //     localStorage.setItem('time', 0);
                // };
            };
        },
        /**心跳检测 */
        setBeatCheck() {
            this.beatTimer && clearTimeout(this.beatTimer);
            this.beatTimer = setTimeout(() => {
                this.ws.send(JSON.stringify({ type: "get_now_time" }));
                this.setBeatCheck();
            }, 59000);
        },
        clearLive() {
            const parentNode = this.$refs.parentLiveVideo;
            parentNode && this.$set(parentNode, "innerHTML", "");
        }
    }
}
</script>

<style scoped lang="scss">
    .stu-live {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        background: #070932;
    }
    .left-info {
        display: flex;
        flex-direction: column;
        width: 38%;
        margin: 34px 37px 0 28px;

        .name-avatar {
            display: flex;
            flex-direction: row;
            width: fit-content;
            height: 48px;
            background: #262963;
            border-radius: 24px;

            .stu-avatar {
                display: inline-block;
                width: 46px;
                height: 46px;
                border-radius: 50%;
                // border: 1px solid #DADCE7;
            }

            .nickname-fans {
                display: flex;
                flex-direction: column;
                width: fit-content;
                height: 32px;
                margin: 5px 0 8px 10px;
                // border: 1px solid #DADCE7;
            }
            .stu-nickname {
                display: inline-block;
                height: 16px;
                font-size: 16px;
                font-weight: bold;
                color: #FFFFFF;
            }
            .fans-number {
                display: inline-block;
                height: 12px;
                margin-top: 8px;
                font-size: 12px;
                font-weight: 400;
                color: #FFFFFF;
            }
            .follow {
                width: 53px;
                height: 40px;
                margin: 4px 0 4px 36px;
                text-align: center;
                background: linear-gradient(85deg, #FB2D69, #FF3E6C, #FC2A7D);
                border-radius: 20px;
                cursor: pointer;
            }
            .follow-word {
                display: inline-block;
                height: 16px;
                margin-top: 8px;
                font-size: 16px;
                font-weight: bold;
                color: #FFFFFF;
            }
            .follow-icon {
                display: inline-block;
                width: 21px;
                height: 21px;
                margin-top: 8px;
                background: url('../../assets/image/othersLive/follow.png');
            }
        }

        .stu-label {
            display: flex;
            flex-direction: row;
            // width: 247px;
            height: 30px;
            margin-top: 20px;
            .stu-label-item {
                display: flex;
                flex-direction: row;
                width: fit-content;
                height: 28px;
                line-height: 30px;
                margin-right: 5px;
                background: #262963;
                border-radius: 14px;
            }
            .label-icon {
                display: inline-block;
                width: 16px;
                height: 15px;
                margin: 7px 5px 6px 5px;
                background: url('../../assets/image/othersLive/label.png');
            }
            .label-word {
                display: inline-block;
                width: fit-content;
                padding-right: 10px;
                font-size: 16px;
                color: #FFFFFF;
            }
        }

        .stu-synopsis {
            margin: 21px 0 21px 0;
            font-size: 16px;
            color: #fff;
        }

        .stu-likes-number {
            display: flex;
            flex-direction: row;
            height: 53px;
            .likes-icon {
                width: 53px;
                height: 53px;
                border-radius: 50%;
                background: url('../../assets/image/othersLive/dianzan.png');
                cursor: pointer;
            }
            .likes-icon-item {
                width: 53px;
                height: 53px;
                border-radius: 50%;
                background: url('../../assets/image/othersLive/dianzan-fu.png');
                cursor: pointer;
            }
            .likes-number {
                margin-left: 20px;
            }
            .likes-numb-word {
                margin-bottom: 6px;
                font-size: 16px;
                color: #fff;
            }
            .likes-n {
                font-size: 24px;
                font-weight: bold;
                color: #4B45FF
            }
            .likes-w {
                font-size: 16px;
                color: #fff;
            }
        }
    }

    .mid-screen {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        width: 29%;
        margin: 20px 21px 19px 0;
        background: url('../../assets/image/student/live_background.png');
        background-size: 100% 100%;
        border-radius: 20px;
        .live-video-container-box {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
        }
        .top-box {
            display: flex;
            .left-bag-coupon {
                display: flex;
                justify-content: space-between;
                width: 125px;
                margin: 12px 0 0 10px;
                .item {
                    position: relative;
                    width: 60px;
                    height: 60px;
                    border-radius: 14px;
                    background-color: rgba(0, 0, 0, 0.23);
                    cursor: pointer;
                    .icon {
                        width: 100%;
                        height: 100%;
                        // transform: rotate(35deg) translate(0, 9px);
                    }
                    .item-container {
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                    }
                    .countdown {
                        position: absolute;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 50px;
                        height: 20px;
                        font-size: 12px;
                        color: #fff;
                        border-radius: 10px;
                        background-color: rgba(0, 0, 0, 0.5);
                        opacity: 0.9;
                    }
                    .corner-mark {
                        position: absolute;
                        top: 0;
                        right: 0;
                        transform: translate(40%, -40%);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 20px;
                        height: 20px;
                        font-size: 14px;
                        color: #d50715;
                        background: #fece0a;
                        box-shadow: 0px 4px 7px 0px rgba(94, 57, 1, 0.3);
                        border-radius: 50%;
                    }
                }
            }
            .right-goods-info {
                flex: 1;
                box-sizing: border-box;
                display: flex;
                justify-content: flex-end;
                position: relative;
                height: 135px;
                width: 100%;
                margin: 100px 24px 0 0;
                .left-paster {
                    // flex: 1;
                    flex: 1.6;
                    margin-right: 15px;
                    .paster-img {
                        height: 135px;
                        width: 100%;
                    }
                }
                .right-info {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    // .title {
                    //     display: flex;
                    //     flex-direction: row-reverse;
                    //     width: fit-content;
                    //     ::v-deep p{
                    //         // width: fit-content;
                    //         height: 100px;
                    //         color: #000;
                    //     }
                    //     ::v-deep img{
                    //         width: 100px;
                    //         height: 100px;
                    //         margin-right: 6px;
                    //     }
                    // }
                    .price-box {
                        font-size: 16px;
                        color: #000;
                        // margin-left: 50%;
                        .old-price {
                            margin-bottom: 5px;
                            .price {
                                text-decoration: line-through;
                            }
                        }
                        .new-price {
                            .price {
                                font-size: 16px;
                                color: #b41222;
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }
        }
        .bottom-box {
            display: flex;
            justify-content: space-between;
            margin: 0 10px 21px 10px;
            overflow: hidden;
            .lift-bullet-action {
                display: flex;
                flex-direction: column;
                .bullet-chat {
                    height: 270px;
                    width: 100%;
                    overflow: hidden;
                    margin-bottom: 15px;
                    .bullet-chat-list {
                        width: 64%;
                        .item {
                            width: fit-content;
                            margin-bottom: 10px;
                            padding: 5px 10px;
                            word-break: break-all;
                            font-size: 16px;
                            color: #ff3e6c;
                            border-radius: 15px;
                            background-color: rgba(0, 0, 0, 0.3);
                            .content {
                                margin-left: 9px;
                                color: #fff;
                            }
                        }
                        .item:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
            .action-input {
                position: relative;
                width: 254px;
            }
            ::v-deep .input {
                height: 48px;
                input {
                    height: 100%;
                    padding: 0 12px;
                    color: #fff;
                    background-color: rgba(0, 0, 0, 0.4);
                    border: none;
                    border-radius: 23px;
                }
            }
            .action-icon {
                position: absolute;
                top: 8px;
                right: 10px;
                display: inline-block;
                width: 22px;
                height: 31px;
                background: url('../../assets/image/othersLive/yuyin.png');
                cursor: pointer;
            }
            .right-goods-popup {
                position: absolute;
                right: 10px;
                bottom: 23px;
                width: 176px;
                // height: 267px;
                box-sizing: border-box;
                align-self: flex-end;
                background-color: #fff;
                border-radius: 6px;
                .img-box {
                    position: relative;
                    width: 170px;
                    height: 170px;
                    margin: 2px 2px 0 2px;
                    overflow: hidden;
                    .explain {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 68px;
                        height: 20px;
                        font-size: 14px;
                        color: #FFFFFF;
                        text-align: right;
                        background: url('../../assets/image/othersLive/explain.png');
                    }
                    .goods-img {
                        width: 100%;
                        height: 100%;
                        vertical-align: middle;
                        border-radius: 6px 6px 0 0;
                    }
                }
                .goods-info {
                    margin: 7px 5px;
                    overflow: hidden;
                    .title {
                        box-sizing: border-box;
                        height: 31px;
                        font-size: 14px;
                        line-height: 18px;
                        color: #222;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2; /*几行就写数字几*/
                        text-overflow: ellipsis;
                    }
                    .coupon {
                        height: 42px;
                        margin-top: 7px;
                        line-height: 42px;
                        color: #fff;
                        background: url('../../assets/image/othersLive/rob.png');
                        cursor: pointer;
                        .coupon-item {
                            font-size: 12px;
                        }
                        .coupon-item-numb {
                            font-size: 18px;
                        }
                    }
                    .coupon span {
                        display: inline-block;
                    }
                    .coupon span:first-child {
                        margin-left: 16px;
                    }
                }
            }
        }
        .live-video {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            ::v-deep video {
                width: 100%;
                height: 100%;
                object-fit: cover !important;
            }
        }
        .end-live {
            position: absolute;
            top: 45%;
            width: 100%;
            height: 50px;
            text-align: center;
            line-height: 50px;
            font-size: 22px;
            font-weight: 500;
        }
    }

    .right-operation {
        display: flex;
        flex-direction: column;
        width: 29%;
        margin: 20px 20px 0 0;
        .top-box {
            display: flex;
            flex-direction: column;
            height: 369px;
            margin-bottom: 21px;
            background: #0C0E3F;
            border: 1px solid rgba(67, 154, 255, 0.15);
            box-shadow: 0px 0px 40px 0px rgba(12, 21, 48, 0.3);
            border-radius: 20px;
            .title-box {
                box-sizing: border-box;
                display: flex;
                align-items: center;
                margin-top: 27px    ;
                margin-bottom: 32px;
                padding-left: 20px;
                font-size: 18px;
                .decorate {
                    width: 31px;
                    height: 11px;
                    margin-right: 10px;
                    vertical-align: middle;
                }
                .title {
                    font-size: 18px;
                    font-weight: bold;
                    color: #FFFFFF;
                }
            }
            .list {
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                .container {
                    box-sizing: border-box;
                    width: 75%;
                    overflow: hidden;
                }
                .rank-item {
                    display: flex;
                    flex-direction: row;
                    margin: 0 0 15px 32px;
                }
                .rank {
                    width: 27px;
                    height: 29px;
                    line-height: 29px;
                    font-size: 16px;
                    font-weight: bold;
                    color: #FFFFFF;
                    margin-right: 9px;
                }
                .rank-one {
                    background: url('../../assets/image/othersLive/one.png') no-repeat;
                }
                .rank-two {
                    background: url('../../assets/image/othersLive/two.png') no-repeat;
                }
                .rank-three {
                    background: url('../../assets/image/othersLive/three.png') no-repeat;
                }
                .rank-word {
                    width: 20px;
                    height: 29px;
                    margin-left: 8px;
                }
                .item {
                    box-sizing: border-box;
                    display: flex;
                    width: fit-content;
                    height: 30px;
                    padding-right: 30px;
                    font-size: 16px;
                    color: #fff;
                    background-color: rgba(0, 0, 0, 0.3);
                    border-radius: 15px;
                    .avatar-box {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 24px;
                        height: 24px;
                        margin-right: 9px;
                        border-radius: 50%;
                        overflow: hidden;
                        background-color: #fff;
                        vertical-align: middle;
                        .avatar-img {
                            width: 24px;
                            height: 24px;
                        }
                    }
                    .desc {
                        flex: 1;
                        overflow: hidden;
                        line-height: 30px;
                        .username {
                            color: #4b45ff;
                        }
                    }
                }
            }
        }
        .mid-box {
            display: flex;
            flex-direction: column;
            overflow: hidden;
            height: 545px;
            margin-bottom: 47px;
            background: #0C0E3F;
            border: 1px solid rgba(67, 154, 255, 0.15);
            box-shadow: 0px 0px 40px 0px rgba(12, 21, 48, 0.3);
            border-radius: 20px;
            padding-right: 36px;
            .title-box {
                box-sizing: border-box;
                display: flex;
                align-items: center;
                margin-top: 27px    ;
                margin-bottom: 32px;
                padding-left: 20px;
                font-size: 18px;
                .decorate {
                    width: 31px;
                    height: 11px;
                    margin-right: 10px;
                    vertical-align: middle;
                }
                .title {
                    font-size: 18px;
                    font-weight: bold;
                    color: #FFFFFF;
                }
            }
            .list {
                box-sizing: border-box;
                width: 100%;
                .container {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    overflow: hidden;
                }
                .item {
                    box-sizing: border-box;
                    display: flex;
                    width: fit-content;
                    height: fit-content;
                    overflow: hidden;
                    padding-right: 10px;
                    margin-left: 35px;
                    margin-bottom: 15px;
                    font-size: 16px;
                    color: #fff;
                    background-color: rgba(0, 0, 0, 0.3);
                    border-radius: 15px;
                    .avatar-box {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 24px;
                        height: 24px;
                        margin-right: 9px;
                        border-radius: 50%;
                        overflow: hidden;
                        background-color: #fff;
                        vertical-align: middle;
                        .avatar-img {
                            width: 24px;
                            height: 24px;
                        }
                    }
                    .desc {
                        width: fit-content;
                        word-break: break-all;

                        line-height: 30px;
                        .username {
                            color: #4b45ff;
                        }
                    }
                }
            }
        }
        .bottom-box {
            display: flex;
            justify-content: space-between;
            height: 76px;
            .barrage-switch {
                width: 93px;
                height: 100%;
                cursor: pointer;
                .open {
                    height: 36px;
                    margin-bottom: 4px;
                    background: url('../../assets/image/othersLive/bullet-open.png');
                }
                .shut {
                    height: 36px;
                    background: url('../../assets/image/othersLive/bullet-shut.png');
                }
            }
            .volume-adjusting {
                display: flex;
                flex-direction: row;
                margin-top: 10px;
                .volume-icon {
                    width: 22px;
                    height: 16px;
                    margin-right: 5px;
                    background: url('../../assets/image/othersLive/shengyin.png');
                }
                .volume-progress {
                    position: relative;
                    width: 150px;
                    height: 16px;
                    .progress {
                        margin-bottom: 6px;
                        height: 1px;
                        background: #262963;
                        border: 2px solid #262963;
                        border-radius: 2px;
                    }
                    .progress::-webkit-progress-value {
                        position: absolute;
                        top: 6px;
                        height: 1px;
                        background: #4B45FF;
                        border: 2px solid #4B45FF;
                        border-radius: 2px;
                    }
                    .progress_btn {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        cursor: pointer;
                        background: #EEEEEE;
                    }
                }
            }
            .enlarge {
                width: 18px;
                height: 17px;
                background: url('../../assets/image/othersLive/lashen.png');
            }
        }
    }

    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }
    .luckyBagDialog {
        display: flex;
        flex-direction: column;
        .avatar {
            position: absolute;
            top: -60px;
            left: 35%;
            width: 100px;
            height: 100px;
            .stu-avatar {
                width: 100px;
                height: 100px;
                border-radius: 50%;
            }
        }
        .condition {
            width: 180px;
            height: 18px;
            margin: 46px auto;
            font-size: 18px;
            font-weight: 500;
            color: #050213;
        }
        .icon {
            width: 140px;
            height: 149px;
            margin: 36px auto;
            background: url('../../assets/image/othersLive/lucky-bag.png');
        }
        .bottom-box {
            display: flex;
            flex-direction: row;
            height: 60px;
            margin: 29px 0 0 0;
            text-align: center;
            line-height: 54px;
            border-top: 1px solid #DDDDDD;
            cursor: pointer;
            .left-btn {
                flex: 1;
                height: 54px;
                font-size: 16px;
                color: #060111;
                border-right: 1px solid #DDD;
            }
            .right-btn {
                flex: 1;
                font-size: 16px;
                color: #4B45FF;
            }
        }

        .title {
            // width: 36px;
            height: 18px;
            margin: 0 auto;
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            color: #070932;
        }
        .mid-box {
            display: flex;
            flex-direction: column;
            width: 360px;
            height: 180px;
            margin: 60px 19px 0 21px;
            .count-down {
                display: flex;
                justify-content: space-between;
                height: 80px;
                background: #F8F8F8;
                border-radius: 4px;
                margin-bottom: 20px;
                .times {
                    width: 84px;
                    height: 41px;
                    margin: 21px 0 16px 10px;
                    border-right: 1px solid #DDD;
                    .times-numb {
                        width: 63px;
                        height: 19px;
                        margin-bottom: 9px;
                        font-size: 24px;
                        font-weight: bold;
                        color: #4B45FF;
                    }
                    .times-word {
                        width: 42px;
                        height: 14px;
                        font-size: 14px;
                        font-weight: 400;
                        color: #5C5C6F;
                    }
                }
                .people-numb {
                    // width: 164px;
                    height: 17px;
                    margin: 32px 10px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #5C5C6F;
                }
            }
            .participate {
                display: flex;
                flex-direction: column;
                height: 80px;
                background: #F8F8F8;
                border-radius: 4px;
                .part-condition {
                    height: 18px;
                    margin: 18px 0 13px 10px;
                    font-size: 18px;
                    font-weight: 500;
                    color: #5C5C6F;
                }
                .part-word {
                    height: 14px;
                    margin: 0 0 17px 12px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #5C5C6F;
                }
            }
        }
        .send {
            width: 360px;
            height: 40px;
            margin: 40px 20px 21px 20px;
            font-size: 14px;
            font-weight: 400;
            color: #FFFFFF;
            text-align: center;
            line-height: 40px;
            background: #FF3E6C;
            border-radius: 4px;
            cursor: pointer;
        }
    }
    ::v-deep .luckyBagDialog {
        .el-dialog {
            height: 380px;
            margin-left: 45%;
            border-radius: 20px;
        }
        .el-dialog__body {
            padding: 0;
        }
        .el-icon-close:before {
            content: ''
        }
    }
    .couponDialog {
        display: flex;
        flex-direction: column;
        .avatar {
            width: 60px;
            height: 60px;
            margin: -30px auto;
            border-radius: 50%;
            .stu-avatar {
                width: 60px;
                height: 60px;
                border-radius: 50%;
            }
        }
        .coupon-tips {
            width: 146px;
            height: 19px;
            margin: 40px auto;
            font-size: 18px;
            font-weight: 500;
            color: #FFFFFF;
        }
        .coupon-details {
            width: 316px;
            height: 260px;
            text-align: center;
            background: url('../../assets/image/othersLive/coupon-bag-item.png');
            background-size: 100% 100%;
            .discount {
                padding-top: 15px;
                color: #FE3266;
                .discount-numb {
                    font-size: 48px;
                }
                .discount-word {
                    font-size: 18px;
                }
            }
            .category-name {
                display: flex;
                flex-direction: row;
                margin: 20px 0 20px 55px;
                .name {
                    // width: 53px;
                    height: 25px;
                    background: #F9D6DF;
                    border-radius: 4px;
                    .name-item {
                        width: 46px;
                        height: 14px;
                        font-size: 14px;
                        font-weight: bold;
                        color: #FE3266;
                        line-height: 25px;
                    }
                }
                .category {
                    // width: 152px;
                    height: 18px;
                    font-size: 18px;
                    font-weight: bold;
                    color: #1B162A;
                }
            }
            .use-limited {
                margin-left: 57px;
                text-align: left;
                font-size: 14px;
                font-weight: bold;
                color: #5C5C6F;
                .use-details {
                    margin-bottom: 10px;
                }
            }
            .use-now {
                width: 284px;
                height: 40px;
                line-height: 40px;
                margin: 9% 16px 14px 20px;
                background: #FE3266;
                border-radius: 6px;
                cursor: pointer;
            }
            .use-now span{
                width: 64px;
                height: 16px;
                font-size: 16px;
                font-weight: 400;
                color: #FFFFFF;
            }

        }
    }
    ::v-deep .couponDialog {
        .el-dialog {
            height: 440px;
            margin: 38% 0 0 46%;
            box-shadow: 0 1px 3px rgba(0,0,0, 0);
            background: url('../../assets/image/othersLive/coupon-bag.png');
            background-size: 100% 100%;
        }
        .el-icon-close:before {
            content: ''
        }
    }
    .coupon-popup {
        .content {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            padding: 16px 18px;
            .coupon-item {
                display: flex;
                width: 100%;
                height: 133px;
                margin-bottom: 8px;
                border: 1px solid #fe3266;
                border-left-style: dashed;
                /* border-right: 0; */
                overflow: hidden;
                .left-coupon-size {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 130px;
                    padding: 20px 0;
                    color: #fe3266;
                    font-size: 48px;
                    text-align: center;
                    .discount .decorate {
                        margin-left: 5px;
                    }
                    .decorate {
                        font-size: 16px;
                        font-weight: 700;
                    }
                    .full-reduction {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        .decorate {
                            font-size: 18px;
                        }
                    }
                    .amount {
                        font-weight: 700;
                        font-size: 14px;
                    }
                }
                .center-info {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding: 23px 10px 23px 0;
                    .title-box {
                        display: flex;
                        .decorate {
                            padding: 4px;
                            margin-right: 10px;
                            font-size: 14px;
                            color: #fe3266;
                            font-weight: 700;
                            background-color: #f9d6df;
                            border-radius: 4px;
                        }
                        .title {
                            flex: 1;
                            font-size: 18px;
                            color: #1b162a;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            font-weight: 700;
                        }
                    }
                    .desc-info {
                        font-size: 14px;
                        color: #5c5c6f;
                        font-weight: 700;
                        & .info-item:last-child {
                            margin-top: 5px;
                        }
                    }
                }
                .right-action {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 138px;
                    height: 100%;
                    font-size: 27px;
                    color: #fff;
                    background-image: linear-gradient(179deg, #ff7073, #f63849);
                    cursor: pointer;
                    .mask {
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        top: 0;
                        background-color: transparent;
                        transition: all 0.3s;
                    }
                    &:hover .mask {
                        background-color: rgba(255, 255, 255, 0.3);
                    }
                    .wavy-line {
                        position: absolute;
                        left: -7.5px;
                        .circle-item {
                            width: 15px;
                            height: 15px;
                            margin-top: 5px;
                            border-radius: 50%;
                            background-color: #fff;
                        }
                        .circle-item:first-child {
                            margin-top: 0;
                        }
                    }
                    .row-item {
                        text-align: center;
                        letter-spacing: 5px;
                        transform: translateX(2.5px);
                    }
                }
                .added {
                    background-image: linear-gradient(160deg, #ddd, #aaa);
                    cursor: default;
                }
            }
        }

    }
</style>
<style lang="scss">
    #live-video {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        video {
            width: 100%;
            height: 100%;
            object-fit: cover !important;
        }
    }
    </style>
